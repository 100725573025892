import { graphql } from "gatsby";
import React, { Fragment } from "react";
import SEO from "../components/SEO";
import useForm from "../utils/useForm";
import Img from "gatsby-image";
import calculatePizzaPrice from "../utils/calculatePizzaPrice";
import formatMoney from "../utils/formatMoney";
import OrderStyles from "../styles/OrderStyles";
import MenuItemStyles from "../styles/MenuItemStyles";
import usePizza from "../utils/usePizza";
import PizzaOrder from "../components/PizzaOrder";
import calculateOrderTotal from "../utils/calculateOrderPrice";

export default function OrderPage({ data }) {
    const pizzas = data.pizzas.nodes;
    const { values, updateValue } = useForm({
        name: "",
        email: "",
        mapleSyrup: "",
    });

    const { order, addToOrder, removeFromOrder, error, loading, message, submitOrder } = usePizza({ pizzas, values });
    if (message) {
        return <p>{message}</p>;
    }
    return (
        <Fragment>
            <SEO title="Order a Pizza!" />
            <OrderStyles onSubmit={submitOrder}>
                <fieldset disabled={loading}>
                    <legend>Your Info</legend>
                    <label htmlFor="name">Name</label>
                    <input type="text" name="name" id="name" value={values.name} onChange={updateValue} />
                    <label htmlFor="email">Email</label>
                    <input type="text" name="email" id="email" value={values.email} onChange={updateValue} />
                    <input
                        type="mapleSyrup"
                        className="mapleSyrup"
                        name="mapleSyrup"
                        id="mapleSyrup"
                        value={values.mapleSyrup}
                        onChange={updateValue}
                    />
                </fieldset>
                <fieldset disabled={loading} className="menu">
                    <legend>Menu</legend>
                    {pizzas.map((pizza) => (
                        <MenuItemStyles key={pizza.id}>
                            <Img width="50" height="50" fluid={pizza.image.asset.fluid} alt={pizza.name} />
                            <div>
                                <h2>{pizza.name}</h2>
                            </div>
                            <div>
                                {["S", "M", "L"].map((size) => (
                                    <button
                                        type="button"
                                        key={size}
                                        onClick={() => addToOrder({ id: pizza.id, size: size })}
                                    >
                                        {size} {formatMoney(calculatePizzaPrice(pizza.price, size))}
                                    </button>
                                ))}
                            </div>
                        </MenuItemStyles>
                    ))}
                </fieldset>
                <fieldset disabled={loading} className="order">
                    <legend>Order</legend>
                    <PizzaOrder order={order} removeFromOrder={removeFromOrder} pizzas={pizzas} />
                </fieldset>
                <fieldset disabled={loading}>
                    <h3>Your Total is {formatMoney(calculateOrderTotal(order, pizzas))}</h3>
                    <div>{error ? <p>Error: {error}</p> : ""}</div>
                    <button type="submit" disabled={loading}>
                        {loading ? "Placing Order..." : "Order Ahead"}
                    </button>
                </fieldset>
            </OrderStyles>
        </Fragment>
    );
}

export const query = graphql`
    query {
        pizzas: allSanityPizza {
            nodes {
                name
                id
                slug {
                    current
                }
                price
                image {
                    asset {
                        fluid(maxWidth: 100) {
                            ...GatsbySanityImageFluid
                        }
                    }
                }
            }
        }
    }
`;
